import * as React from "react";
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import { IS_NO_INDEX, URL_NAME } from "../../config/constans";

interface MetaProps {}

const Meta: FC<MetaProps> = () => {
  useEffect(() => {
    firebase.analytics();
  }, []);

  return (
    <>
      <Helmet>
        {IS_NO_INDEX && <meta name="robots" content="noindex" />}

        <meta charSet="utf-8" />
        <meta
          name="description"
          content="「ほとばしる創造性 そのままに － 情熱の戦士プログラム －」©2019 by mogFlowts"
        />

        <meta property="og:url" content={`${URL_NAME}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="mogFlowts" />
        <meta
          property="og:description"
          content="「ほとばしる創造性 そのままに － 情熱の戦士プログラム －」©2019 by mogFlowts"
        />
        <meta property="og:image" content={`${URL_NAME}/Logo_α2.png`} />
        <meta
          property="og:image:secure_url"
          content={`${URL_NAME}/Logo_α2.png`}
        />
        <meta property="og:image:type" content="image/png" />

        <meta name="twitter:card" content="summary" />

        <title>mogFlowts</title>
        <link rel="icon" href="/favicon.ico" />
        <script
          src="https://kit.fontawesome.com/557ec7b1cd.js"
          crossOrigin="anonymous"
        />
      </Helmet>
    </>
  );
};

export { Meta };
