import * as React from "react";
import { FC } from "react";
import styled from "styled-components";
import { theme } from "../theme";
import MediaQuery from "react-responsive";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  return (
    <Container>
      <Main>
        <MediaQuery query={`(max-width: ${theme.ConstBreakPoint.MAX_SP})`}>
          <img
            alt={"footer"}
            src={
              "https://res.cloudinary.com/mogflowts/image/upload/v1588509747/web/Footer_SP_gsfeex.png"
            }
          />
        </MediaQuery>

        <MediaQuery query={`(min-width: ${theme.ConstBreakPoint.MIN_PC})`}>
          <img
            alt={"footer"}
            src={
              "https://res.cloudinary.com/mogflowts/image/upload/h_200/v1588415297/web/Footer_dhz2zk.png"
            }
          />
        </MediaQuery>
      </Main>
    </Container>
  );
};

export { Footer };

const Container = styled.div`
  margin: 0;
  background-color: ${theme.colors.grey10};

  width: 100%;
  height: ${theme.height.footerSP};

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: 100%;
    height: ${theme.height.footer};
  }
`;

const Main = styled.div`
  margin: 0 auto;

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  color: ${theme.colors.grey50};

  img {
    width: 80%;
    max-width: 500px;
    max-height: 150px;
  }

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: 1024px;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    text-align: inherit;

    img {
      width: inherit;
      max-width: 800px;
      max-height: inherit;
    }
  }
`;
