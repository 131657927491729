export default function stringToBoolean(
  value: string | null | undefined
): boolean {
  // null、undefined は false
  if (!value) {
    return false;
  }

  // 小文字にして判定
  return value.toLowerCase() === "true" || value === "1";
}
