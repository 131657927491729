import * as React from "react";
import { FC, useState } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import MediaQuery from "react-responsive";
import { theme } from "../theme";
import HamburgerMenu from "react-hamburger-menu";

export const navItemList: NavItemType[] = [
  {
    title: "ABOUT",
    url: "/about",
  },
  {
    title: "ACTIVITIES",
    url: "/activities",
  },
  {
    title: "WORKS",
    url: "/works",
  },
  {
    title: "CONTACT",
    url: "/contact",
  },
];

interface NavBarProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export type NavItemType = {
  title: string;
  url: string;
};

export const NavBar: FC<NavBarProps> = ({ isOpen, setIsOpen }) => {
  return (
    <Container>
      {/* SP */}
      <MediaQuery query={`(max-width: ${theme.ConstBreakPoint.MAX_SP})`}>
        <ContentSP>
          <Link to={"/"}>
            <img
              src={
                "https://res.cloudinary.com/mogflowts/image/upload/w_60/v1588386492/web/logo_%CE%B1_s_iirpia.png"
              }
              alt="Logo"
            />
          </Link>

          <HamburgerMenu
            isOpen={isOpen}
            menuClicked={() => setIsOpen(!isOpen)}
            width={18}
            height={15}
            strokeWidth={1}
            rotate={0}
            color="black"
            borderRadius={0}
            animationDuration={0.5}
          />
        </ContentSP>
      </MediaQuery>

      {/* PC */}
      <MediaQuery query={`(min-width: ${theme.ConstBreakPoint.MIN_PC})`}>
        <ContentPC>
          <Link to={"/"}>
            <img
              src={
                "https://res.cloudinary.com/mogflowts/image/upload/w_80/v1588386492/web/logo_%CE%B1_s_iirpia.png"
              }
              alt="Logo"
            />
          </Link>
          {navItemList.map((item, index) => {
            return (
              <NavItem key={index} to={item.url}>
                <div>{item.title}</div>
              </NavItem>
            );
          })}
        </ContentPC>
      </MediaQuery>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: ${theme.ConstZIndex.NavBar};
  left: 0;
  right: 0;

  width: 100%;
  height: ${theme.height.navBarSP};

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    height: ${theme.height.navBar};
  }
`;

const ContentSP = styled.div`
  padding: 10px 15px;

  width: 100%;
  height: 100%;

  background-color: ${theme.colors.white};
  box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    display: flex;
    justify-items: center;
    align-items: center;
  }
`;

const ContentPC = styled.div`
  margin: 0 auto;
  padding: 0 40px;

  width: 100%;
  height: 100%;

  background-color: ${theme.colors.white};
  box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  // Image
  img {
    isplay: flex;
    justify-items: center;
    align-items: center;
  }
  & > :nth-child(1) {
    margin-right: 30px;

    :hover {
      cursor: pointer;
    }
  }
`;

const NavItem = styled(Link)`
  margin: 0 30px;
  padding: 0 ${theme.width.paddingSideSP};

  text-decoration: none;
  color: ${theme.colors.grey40};
  font-weight: 600;

  text-align: center;

  // Title
  & > div:nth-child(1) {
  }

  // SubTitle
  & > div:nth-child(2) {
    font-size: 0.8em;
  }

  :visited {
    color: ${theme.colors.grey40};
  }
`;
