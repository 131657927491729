import * as React from "react";
import { FC } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { theme } from "../theme";
import HamburgerMenu from "react-hamburger-menu";
import { navItemList } from "../Navbar";

interface SideBarProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const SideBar: FC<SideBarProps> = ({ isOpen, setIsOpen }) => {
  return (
    <Container isOpen={isOpen}>
      <div>
        <HamburgerMenu
          isOpen={isOpen}
          menuClicked={() => setIsOpen(!isOpen)}
          width={18}
          height={15}
          strokeWidth={1}
          rotate={0}
          color="black"
          borderRadius={0}
          animationDuration={0.5}
        />
      </div>

      <div>
        {navItemList.map((item, index) => (
          <SidebarItem
            key={index}
            to={item.url}
            activeStyle={{ color: theme.colors.grey60 }}
          >
            {item.title}
          </SidebarItem>
        ))}
      </div>
    </Container>
  );
};

interface ContainerProps {
  isOpen: boolean;
}

const Container = styled.div<ContainerProps>`
  display: ${props => (props.isOpen ? "block" : "none")};

  position: fixed;
  z-index: ${theme.ConstZIndex.SideBar};

  width: 80vw;
  max-width: 300px;
  height: 100vh;

  top: 0;
  right: 0;

  background-color: white;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  & > div:nth-child(1) {
    padding: 10px 15px;

    width: 100%;
    height: ${theme.height.navBarSP};

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const SidebarItem = styled(Link)`
  margin: 15px 30px;
  padding: 0 ${theme.width.paddingSideSP};

  text-decoration: none;
  color: ${theme.colors.grey40};
  font-weight: 600;

  text-align: center;

  :visited {
    color: ${theme.colors.grey40};
  }
`;
